import React, { useState } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import RoutinesVideo from "../../assets/routines.mp4"
import AlsoHelpsMenu from "../../components/alsoHelpsMenu"
import { playVideo, playVideoTitle, bridgeAction } from "../../components/utils"
import VideoWrapper from "../../components/videoWrapper"

const Page = ({ data }) => {
  const [playingVideo, setPlayingVideo] = useState(false)

  // TODO seo
  return (
    <Layout containerId="home">
      <Seo title="Mente" />

      <div className="feature-sections">
        <section className="full-width" style={{ marginBottom: "100px" }}>
          <div className="grid full-width-grid">
            <div className="full-width-grid" style={{ textAlign: "center" }}>
              <h2 className="feature-group-title">Bygg gode rutiner</h2>
              <p className="feature-group-decription inapp-invisible">
                Design dagene dine og ligg i forkant med en morgenrutine,
                <br />
                kveldsrutine eller ukentlig rutine.
              </p>

              <div id="feature-cta-wrapper">
                <button
                  className="btn-cta"
                  onClick={() => playVideo("demo", "feature-cta-wrapper")}
                  style={{ marginBottom: "0.5em" }}
                >
                  {playVideoTitle(playingVideo, "1. Se hvordan")}
                </button>
                <br />
                <button
                  className="btn-cta"
                  onClick={() => bridgeAction("routines")}
                  style={{ marginBottom: "0.5em" }}
                >
                  2. Bygg din egen rutine
                </button>
              </div>

              <div style={{ marginTop: "2rem" }}>
                <VideoWrapper
                  id="video_demo"
                  video={RoutinesVideo}
                  videoEvent={setPlayingVideo}
                />
              </div>
            </div>
          </div>
        </section>

        <AlsoHelpsMenu except="routines" />
      </div>
    </Layout>
  )
}

export default Page
